.admin-layout {

/* FlexForm ----------------------- */

.FlexForm .Fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
  min-width: 0;
  padding-bottom: 0rem;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.FlexForm--center {
  align-content: center;
}

.FlexForm hr {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0.5rem;
}

/* AdminFields ----------------------- */

.AdminFields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

/* FormRow ----------------------- */

.FormRow {
  flex: 1 0 100%;
  margin-bottom: 2rem;
  padding: 0 0.75rem;
  position: relative;

  .Button {
    margin-bottom: 0;
  }
}

.FormRowHalfContainer {
    display: flex;

    &--center {
      width: 100%;
      align-items: center;
    }

    @mixin below {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

/* Modifiers */

.FormRow--half {
  @mixin above $small {
    flex: 0 0 50%;
  }
}

.FormRow--third {
  @mixin above $small {
    flex: 0 0 33.3333333333%;
  }
}

/* .FormRow--quarter {
  @mixin above $small {
    flex: 0 0 50%;
  }
  @mixin above $large {
    flex: 0 0 25%;
  }
} */

.FormRow--checkboxLabel {
  input {
    display: inline-block;
    width: unset;
    height: unset;
    line-height: 1.75rem;
    margin-right: .5rem;
    vertical-align: text-top;
  }

  label {
    display: inline-block;
    cursor: pointer;
  }
}

/* FormLabel ----------------------- */

.FormLabel, .LabeledValue-label {
  margin-bottom: 0.25rem;

  abbr[title=required] {
    text-decoration: none;
    color: $admin-red;
  }

  @mixin above $small {
    font-size: 1rem;
  }
}

.FormLabel--disabled {
  opacity: 0.4;
}

.FormLabel.FormLabel--secondary {
  font-size: 14px;
  text-transform: none;
  color: black;
  margin-left: .5rem;
  margin-top: .25rem;

  &-noMargins {
    margin-left: 0;
    margin-top: 0;
  }
}


/* LabeledValue ----------------------- */

.LabeledValue {
  margin-bottom: 1rem;
}

.LabeledValue-value--big {
  font-size: 1.35rem;
  line-height: 1.3;
}

.LabeledValue-label {
  opacity: 0.75;
  margin-bottom: 0.1rem;
}


/* TextInput ----------------------- */

.TextInput {
  @mixin rounded-input-admin;
}


/* TextArea ----------------------- */

.TextArea {
  @mixin rounded-input-admin;
  width: 100%;
  line-height: 1.6;
  min-height: 5rem;
}


/* Checkbox ----------------------- */

.Checkbox {
  font-size: 0.875rem;

  input[type=checkbox] {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
  }
}

/* RadioGroup ----------------------- */

.RadioGroup {
  label {
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.875rem;

    span {
      display: inline-block;
      margin-left: 0.4rem;
    }
  }

  &--custom {
    input {
      margin-right: 0.25rem;
    }
  }
}

.BlockCheckboxes .RadioGroup {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-bottom: .25rem;
  }

  label {
    @mixin gordita-medium;
    line-height: 1;
    text-align: center;
    padding: 1rem 1.5rem;
    margin-right: -1px;
    height: 100%;
    border: $border;
    display: flex;
    align-items: center;

    small {
      padding-top: .25rem;
      display: inline-block;
      font-size: 75%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label,
  input.selected + label {
    background: $primary;
    color: white;
  }

  & > div:first-of-type label {
    border-radius: 4px 0 0 4px;
  }

  & > div:last-of-type label {
    border-radius: 0 4px 4px 0;
  }

  input[type=checkbox] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
}


/* FileField ----------------------- */

.FileField {
  position: relative;
  label {
    margin-bottom: 0.25rem;
  }

  .UploadPlusButton {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px $border-color dashed;
    border-radius: 4px;

    .UploadPlusIcon {
      width: 30px;
      height: 30px;
      background: linear-gradient(to bottom, transparent 38%, $primary 38%, $primary 62%,  transparent 62%),
        linear-gradient(to right, transparent 38%, $primary 38%, $primary 62%, transparent 62%);
    }
  }

  .UploadPlusButton--large {
    @mixin gordita-medium;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    border: 3px $mediumDarkGrey dashed;
    color: $text-light;
    font-size: 1.1rem;

    &:hover {
      border: 3px $primary dashed;
    }

    .UploadPlusIcon {
      margin-bottom: 0.75rem;
      width: 20px;
      height: 20px;
      background: linear-gradient(to bottom, transparent 40%, $text-light 40%, $text-light 60%,  transparent 60%),
        linear-gradient(to right, transparent 40%, $text-light 40%, $text-light 60%,  transparent 60%);
    }
  }
}

.FileField input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.FileField .icon-camera {
  font-size: 1.65rem;
  cursor: pointer;
}


/* ErrorExplanation ----------------------- */

.ErrorExplanation, .input .hint,
.input .error, .FormRow .hint, .FileField .hint {
  margin-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.3;
  display: block;

  @mixin above $small {
    font-size: 0.8rem;
  }
}

.hint {
  display: inline-block;
  margin-right: 0.5rem;
  line-height: 1.2;
  color: $text-light;
}

.ErrorExplanation, .input .error {
  @mixin gordita-medium;
  color: $admin-red;
}


/* FormErrors ----------------------- */

.FormMessages {
  @mixin gordita-bold;
  position: relative;
  background: $blue;
  flex: 1 1 auto;
  color: white;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  vertical-align: middle;

  .icon {
    opacity: 0.3;
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: 0.1em;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }

  h4 {
    width: 100%;
    color: white
  }

  a {
    display: inline-block;
    color: white;
    border-bottom: 2px solid rgba(255,255,255,0.25);
  }
}

.FormMessages + .FormMessages {
  margin-top: -1rem;
}

.FormMessages--error {
  background: $blue;
}

/* Date Filter */

.DateFilter {
  background: white;
  border-radius: 24px;
  border: $border;
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: $text-light;
  padding: .25rem 10px;

  .Filter-arrow-zone {
    padding-left: 5px;
    padding-top: 5px;
    cursor: pointer;
  }

  .Filter-arrow {
    display: block;

    &:before {
      display: block;
      font-family: "icons";
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\EA03";
      color: $primary;
    }
  }

  &--open .Filter-arrow:before {
    content: "\EA06";
  }
}

.DateFilter-changeWrapper {
  display: flex;
  vertical-align: middle;
}

.DateFilter-closeIcon {
  background: none;
  border: none;
  padding: 0;
  padding-left: .5rem;
  color: #999;
  font-size: 11px;

  &:hover {
    color: #D0021B;
  }

  &:before {
    padding-top: 3px;
  }
}

.DateFilter-button {
  background: none;
  vertical-align: middle;
  border: none;
  color: $black;
  font-size: 1.35rem;
  margin: 0;
  padding: 0;
  display: inline-block;
  line-height: 1;
  transition: color $hover-speed;
}

.DateFilter-popOver {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .DayPicker {
    clear: both;
    padding: 0;
    padding-top: 1rem;
    border-radius: $radius;
  }

  .DayPicker-Month {
    margin: 0;
  }

  h5 {
    max-width: 50%;
    float: left;
    vertical-align: middle;
  }
}

.Required {
  @mixin gordita-medium;
  color: $admin-red;
  font-size: 0.625rem;
  margin-right: 1rem;
  margin-top: -1.25rem;
  position: absolute;
  right: 0;
  top: 6.25rem;
}

.InlineSearchContainer {
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;

  .Button {
    height: 100%;
  }
}

.InlineSearch {
  display: flex;
  flex-grow: 1;
  border-radius: 20px;
  background: white;
  padding: 1rem;
  padding-top: 9.5px;
  padding-bottom: 5.5px;
  box-shadow: $box-shadow;

  @above {
    margin-right: 1rem;
  }

  img {
    margin-right: .5rem;
  }

  input {
    flex-grow: 1;
    border: none;
    min-width: 200px;

    &::placeholder {
      color: $text-light;
    }
  }
}

.ToggleWrapper {
  display: flex;
  align-items: center;

  &-labelLeft,
  &-labelRight {
    line-height: 1;
    margin-top: 2px;
  }

  &-labelLeft {
    margin-right: .5rem;
  }

  &-labelRight {
    margin-left: .5rem;
  }
}

.FormLabel {
  @mixin secondary-text;

  img {
    vertical-align: sub;
    margin-right: 3px;
  }
}

.BlogPostForm-published {
  width: 100%;

  .FormRow {
    display: flex;
    padding-bottom: 1rem;
    height: 100%;

    .ToggleWrapper {
      margin-left: auto;
    }
  }
}

.FormRow .Select {
  display: flex;

  &.is-focused {
    .Select-placeholder {
      opacity: 0;
    }
  }

  .Select-control {
    height: 3rem;
  }

  .Select-placeholder,
  .Select-value-label,
  .Select-input {
    padding: 0.5em 0.75em .25rem;
  }

  .Select-value-label {
    display: inline-block;
    padding-right: 0;
    padding-left: 0;
  }

  .Select-placeholder,
  .Select-input > input {
    line-height: 34px;
  }

  .Select-input > input {
    padding: 0;
  }

  .Select-arrow-zone {
    vertical-align: unset;
  }

  .Select-clear {
    display: block;
    padding-top: 2px;
  }

  .Select-clear-zone {
    padding-right: .25rem;
  }

  .Select-multi-value-wrapper {
    height: 100%;
  }
}

.FormSubmitContainer {
  display: flex;
  padding-top: 5rem;
  margin-top: auto;

  .Button {
    flex: 1 1 50%;
    margin-right: .5rem;

    & + & {
      margin-left: 1rem;
    }
  }

  .ButtonParent {
    flex: 1 1 50%;
    margin-right: 1rem;

    .Button {
      width: 100%;
    }
  }
}

.FormRow--halfContainer {
  display: flex;

  .FormRow {
    flex: 0;
    padding: 0;
    margin: 0;

    &:last-child {
      padding-left: 1rem;
    }
  }

  .TextInput {
    width: 170px;
  }

  .TextInput-iconContainer .TextInput {
    width: 148px;
  }
}

.FormRow.FormRow--inline {
  display: flex;
  align-items: center;

  .FormLabel {
    margin-right: 1rem;
    margin-bottom: 0;
    line-height: 1;
    padding-top: 2px;
  }
}


.TextInput-iconContainer {
  width: 100%;
  border: $border;
  display: flex;
  border-radius: $radius;
  align-items: center;

  .TextInput {
    border: none;
    padding-left: .25rem;
    height: calc(3rem - 2px);
  }

  .TextInput-icon {
    color: $text-light;
    padding-left: .75rem;
    padding-top: 4px;
  }
}

.TextInput-iconContainer.TextInput-iconContainer--dark {
  .TextInput-icon {
    background: $border-color;
    color: $black;
    font-weight: bold;
    padding: 13px 16px;
    padding-top: 15px;
    line-height: 1;
  }

  .TextInput {
    padding-left: .75rem;
  }
}

}