.admin-layout {

.DateTime {
  width: 100%;
}

.DateTime-datePicker {
  margin-bottom: 1rem;
  .DayPicker {
    display: inline-block;
    max-width: 290px;
    border: 1px solid #ddd;
    border-radius: $radius;
  }
}

.DateTime-times {
  margin-bottom: 0;
}

.DateTime-time {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0;
}

}