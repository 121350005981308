.admin-layout {

.MenuList {
  border-collapse: collapse;
  width: 100%;
}

.NewTruckForm {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);

  .FormRow--checkboxLabel {
    margin-bottom: .5rem;
  }

  .ProgressCounter {
    margin-top: auto;
    padding-bottom: 3rem;
  }
}

}