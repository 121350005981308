.admin-layout {

.OnlineOrdering {

  .OrderingContent {
    flex-direction: column-reverse;

    h2 {
      margin-left: 0;
      margin-top: 2rem;
    }

    iframe {
      width: 100%;
      height: 75vh;
    }

    @mixin above $small {
      flex-direction: row;

      h2 {
        margin-top: 0;
      }

      & > div {
        &:first-child {
          flex: 0 0 66.6666666666%;
        }

        &:last-child {
          flex: 0 0 33.3333333333%;
          img {
            margin-top: -2rem;
          }
        }
      }
    }
  }

}

}