.admin-layout {

.geosuggest {
  position: relative;
  width: 100%;
}

.geosuggest__input {
  @mixin rounded-input-admin;
  width: 100%;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: $border;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  border-radius: 0 0 $radius $radius;
  margin-top: -4px;

}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  color: black;
  border-color: $border-color;
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  cursor: pointer;
  z-index: 5;
  position: relative;
}

.geosuggest__item:hover,
.geosuggest__item:focus,
.geosuggest__item--active,
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background-color: $primary-bg-dark;
  color: black;
}

}