.admin-layout {

.TruckDocument {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem;

  &-label {
    line-height: 1;
    padding-top: 4px;

    a {
      margin-left: .75rem;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
  }

  & + & {
    border-top: $border;
  }

  .document-status {
    margin-left: 1rem;

    & > div {
      display: flex !important;
      align-items: center;
    }
  }

  @mixin below {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
    border-top: none !important;

    &-label {
      margin-bottom: .5rem;
    }

    .document-status {
      margin-left: .5rem;
    }
  }
}

}