.admin-layout {

.AdminAccountForm {

  .AdminAccountForm-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0;
    }
  }

  .AdminAccountForm-VendorHeader {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    text-align: right;

    &--higher {
      margin-top: -2rem;
    }

    .Loader {
      display: inline-block;
      margin-bottom: -4.75px;
      margin-top: -3px;
    }
  }

  &.AdminAccountForm--truck {

    p {
      max-width: 750px;
    }

    .TruckFormHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      &--inner {
        display: flex;
      }

      img {
        margin-right: 1rem;
      }

      h2 {
        margin: 0;
        margin-top: .5rem;
      }

      p {
        font-size: $text-sm;
        color: $text-light;
      }

      .ViewProfileButton {
        padding: .5rem .75rem;
        display: flex;
        align-items: center;

        img {
          margin-top: 1px;
        }

        div {
          margin-top: 4px;
        }
      }
    }

    .TruckFormBanner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 2rem;

      img {
        margin-left: 1rem;
      }

      @mixin below {
        flex-direction: column-reverse;
        padding: 1.5rem;

        h3 {
          margin-bottom: .5rem;
          text-align: center;
        }

        img {
          margin: 0;
          margin-bottom: 1.5rem;
        }
      }
    }

    .SocialConnect {
      display: flex;
      align-items: center;

      @mixin below {
        flex-direction: column;
      }

      &-Header {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1;

        & > div {
          padding-top: 5px;
        }
      }

      &-ButtonContainer {
        margin-left: auto;

        @mixin below {
          margin-top: 0.5rem;
          margin-left: 0;
        }
      }

      &-Status {
        display: flex;
        font-size: 12px;

        &-Text {
          padding-top: 1px;
        }

        .icon {
          margin-right: 6px;
        }
      }

      &-Status--connected {
        color: #166a24;
      }

      &-Status--disconnected {
        color: #d25353;
      }

      .Button--square {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .TruckFormBanner,
    .SocialConnect {
      max-width: 750px;
    }

    .SocialSettings {

      &-Title {
        color: $text-light;
        text-transform: uppercase;
        font-size: 14px;
      }

      &-Image img {
        border: $border;
        border-radius: 4px;
      }

      &-Tip {
        border: 1px dashed $mediumDarkGrey;
        background: $bg;
        box-shadow: none;
      }

      .Box.u-flex {
        @mixin below {
          flex-direction: column;
        }
      }

      .FormRow {
        @mixin above $small {
          flex: 0 0 66.6666666666%;

          &.Switch {
            flex: 0 0 33.3333333333%;
          }
        }
      }

      @mixin below {
        .u-grid-row {
          flex-direction: column-reverse !important;
        }
      }

    }

    .SocialSettings + .SocialSettings {
      margin-top: 3rem;
    }

    .VendorProfile .FormRow,
    .VendorSocial .FormRow {
      padding: 0;
    }

    h3.large {
      font-size: 1.3em;
    }

    h4.large {
      font-size: 1.12em;
    }

    .CoverPhotoIcon {
      color: $yellow;
    }

    @mixin below {
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);

      .TruckFormHeader {
        flex-direction: column;
        margin-bottom: 1.5rem;
      }

      .TruckFormHeader--inner + div {
        margin-top: 1rem;
      }

      .BusinessProfile {
        flex-direction: column-reverse;

        img {
          margin-left: 0;
          margin-bottom: 1rem;
        }
      }
    }

  }

  &.TruckAccountForm .FormRow {
    padding: 0;
  }

  @mixin below {
    .AdminAccountForm-Header,
    .AdminAccountForm-VendorHeader {
      .Loader, .LoaderContent {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        z-index: 10;
      }
    }

    .AdminAccountForm-VendorHeader {
      margin-top: 0 !important;
    }
  }

  @mixin below $medium {
    .react-tabs__tab-list {
      padding: 0;
    }

    .react-tabs__tab {
      list-style: none;
      margin: 0;
      padding: .5rem 1rem;
      padding-bottom: .25rem;
      cursor: pointer;
      border: 1px solid $primary;
      display: inline-block;
      background-color: white;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      margin-bottom: 5px;

      &--selected {
        color: white;
        background-color: $primary;
      }
    }
  }

  @mixin above $medium {
    .react-tabs {
      display: flex;
      background: white;
      box-shadow: $box-shadow;
      border-radius: 4px;
      overflow: hidden;
    }

    .react-tabs__tab-list {
      width: 25%;
      border-right: $border;
      margin: 0;
    }

    .react-tabs__tab {
      list-style: none;
      margin: 0;
      padding: 2rem;
      padding-right: 4rem;
      cursor: pointer;
      border-bottom: $border;
      border-left: 2px solid white;

      &--selected {
        background-color: $primary-bg;
        border-left: 2px solid $primary;
      }
    }

    .react-tabs__tab-panel {
      &--selected {
        padding: 2rem;
        flex: 1 1 75%;
        min-height: 500px;
      }
    }
  }

}

}