/* ------------------------- */
/* Base HTML Elements        */
/* No classes or IDs         */
/* ------------------------- */

html.admin-layout {
  font-size: 15px;
  color: black;

  body {
    background: $bg;
  }

  a {
    color: $primary;

    &:hover {
      color: $primary-dark;
    }
  }

}