.rdw-editor-wrapper {
  border: 1px solid $mediumGrey;
  border-radius: $radius;

  .rdw-editor-main {
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }

}


/*
 * Toolbar
 */

.rdw-editor-toolbar {
  padding: 5px;
  border: none;
  border-bottom: 1px solid $mediumGrey;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .rdw-option-wrapper,
  .rdw-dropdown-wrapper {
    border: 1px solid white;
    margin: 0px 3px;

    &:hover {
      background: $grey;
      box-shadow: none;
    }
  }

  a.rdw-dropdown-selectedtext {
    color: unset;

    &:hover {
      color: unset;
    }
  }

  .rdw-dropdown-optionwrapper:hover {
    box-shadow: none;
  }

  .rdw-dropdownoption-highlighted {
    background: $grey;
  }

  .rdw-option-active {
    box-shadow: none;
    border: 1px solid #81c9ff;
  }

  .rdw-inline-wrapper,
  .rdw-block-wrapper,
  .rdw-link-wrapper {
    margin: 0;
  }

  .rdw-dropdown-optionwrapper li {
    margin-left: 0;
  }
}