.admin-layout {

.MenuItem {
  display: flex;
  align-items: center;
  background: white;
  border: $border;
  border-radius: 4px;
  padding: .5rem;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  & > div:not(:last-child) {
    margin-right: 1rem;
  }

  .IconButton {
    margin-bottom: -0.5rem;
  }
}

.MenuItem-imageIcon {
  background-size: cover;
  background-position: center center;
  height: 40px;
  width: 40px;
}

.MenuItem-name {
  width: 25%;

  @mixin below {
    width: 50%;
  }
}

.MenuItem-category {
  width: 25%;
}

.MenuItem-delete {
  margin-left: auto;
}

.MenuItem-imageContainer {
  background: $bg;
  border-radius: 4px;
  overflow: hidden;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 2rem;
}

.MenuItem-image {
  display: inline-block;
  position: relative;

  img {
    height: 120px;
  }

  &-delete {
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    height: 20px;
    line-height: 1.65;
    position: absolute;
    right: 0.5rem;
    text-align: center;
    top: 0.5rem;
    width: 20px;
  }
}

}