.admin-layout {

.Table {
  font-size: 1rem;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0px;
  color: $black;
  overflow: hidden;
  overflow-y: auto;
  border-radius: $radius;
}

.Table tr {
  margin: 0;

  td {
    background-color: white;
    border-bottom: $border;
  }

  &:last-child td {
    border: none;
  }
}

.Table td, .Table th {
  margin: 0;
  padding: 0.65rem 0.75rem 0.65rem 0.75rem;

  &:not(:first-child) {
    white-space: nowrap;
  }
}

.Table th {
  display: none;
  @mixin gordita-bold;
  font-weight: 500;

  @mixin above $small {
    display: table-cell;
  }
}

.Table td {

  &:first-child {
    @mixin gordita-medium;
    padding-top: 1rem;
    width: 100%;

    a {
      color: black;

      &:hover {
        color: $primary-dark;
      }
    }
  }


  &:before {
    @mixin h5;
    content: attr(data-label);
    float: left;
    margin-bottom: 0;
    display: block;
  }

  .badge {
    width: 100%;
  }

  @mixin above {
    &:last-child {
      padding-bottom: 1rem;
    }
  }

  @mixin above $medium {
    text-align: left;
    float: none;
    display: table-cell;
    height: 56px;

    &,
    &:first-child,
    &:last-child {
      padding: 7px 1rem;
      padding-bottom: 1px;
    }

    &:before {
      display: none;
    }
  }
}

.Table .Sort-label {
  line-height: 1.15;
  display: inline-block;

  &:active, &:focus {
    outline: none;
  }
}

.Table .Sort-labelArrow {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.25rem;
  position: relative;
  top: 0.1rem;
  color: $primary;
}

}