.admin-layout {

.Button, .IconButton, .LinkButton, .PlainLinkButton, .SelectButton {
  @mixin gordita-regular;
  cursor: pointer;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.Button {
  background: $primary;
  border-radius: 24px;
  display: inline-block;
  padding: 1rem;
  padding-top: 9.5px;
  padding-bottom: 5.5px;
  line-height: 1.15;
  border: none;
  color: white;
  font-weight: 500;

  &:hover {
    color: white;
    background: $primary-dark;

    svg path {
      fill: $primary-dark;
    }
  }

  &:disabled {
    opacity: 60%;

    &:hover {
      background: $primary;
    }
  }

  &--small {
    font-size: 12px !important;
  }

  &--square {
    @mixin gordita-medium;
    border-radius: 6px;
    padding: 35px;
    padding-top: 11px;
    padding-bottom: 7px;
  }

  &.u-strong {
    @mixin gordita-medium;
  }

  &.LinkButton {
    color: $primary;
    background: none;

    &:hover {
      color: $primary-dark;
      background: none;
    }
  }

  &.OutlineButton {
    background: white;
    border: 2px solid $primary;
    color: inherit;

    &--red {
      border-color: $admin-red;

      &:hover {
        border-color: $admin-red-dark !important;
      }
    }

    &:hover {
      border: 2px solid $primary-dark;
      background: $bg;
    }
  }

  &.FilledOutlineButton {
    border: 2px solid $primary;

    &:hover {
      border: 2px solid $primary-dark;
    }
  }

  &.SecondaryOutlineButton {
    background: white;
    border: 1px solid $border-color;
    color: black;
    border-radius: 6px;
    padding: 1.25rem;
    padding-top: 15px;
    padding-bottom: 13px;
    height: 47px;

    img {
      margin-right: .5rem;
      vertical-align: text-bottom;
      margin-top: -2px;
    }

    &:hover {
      border: 1px solid $primary;
    }

    &--small {
      background: white;
      border: 1px solid $border-color;
      color: black;

      &:hover {
        border: 1px solid $primary;
      }
    }
  }
}

.PlainLinkButton {
  color: black;
  display: inline-block;
  background: none;
  border: none;
  font-weight: normal;
  font-size: inherit;

  padding: 0;

  &:hover {
    color: $primary-dark;
  }

  &--bold {
    @mixin gordita-medium;
    font-weight: 500;
  }

  &--blue {
    color: $primary;

    &:hover {
      color: $primary-dark;
    }
  }
}

.SelectButton {
  background: white;
  border: $border;
  border-radius: 4px;
  height: 3rem;
  width: 100%;
  padding: 0.5em 0.75em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--active .SelectButton-Icon {
    transform: scaleY(-1);
  }

  span {
    white-space: nowrap;
  }
}

.IconButton {
  background: none;
  border: none;
  line-height: inherit;
  text-transform: uppercase;
  color: $text-light;
  font-size: .75rem;
  display: inline-block;
  padding: 0;

  @mixin below {
    &--title {
      display: none;
    }
  }

  &:hover {
    color: black;

    .IconButton--icon {

      &.doc > * {
        stroke: $primary;
      }

      &.edit {
        fill: $primary;
      }

      &.trash > * {
        fill: $admin-red;
      }
    }
  }

  & + .IconButton,
  & + .IconButton--parent,
  &--parent + .IconButton {
    margin-left: 1rem;
  }

  .IconButton--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.u-noMobile .doc {
    margin-bottom: 3px;
  }

  .IconButton--icon {
    height: 21px;

    &.edit {
      height: 22px;
      width: 22px;
      margin-bottom: 2px;
    }

    &.cal {
      height: 22px;
    }

    &.trash {
      height: 24px;
      width: 24px;
      fill: $mediumGrey;
    }
  }
}

.IconButton--parent {
  @mixin below {
    height: 23px;
  }
}

.BackLink {
  @mixin secondary-text;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    vertical-align: sub;
  }

  &:hover {
    color: $primary-dark;

    svg path {
      fill: $primary-dark;
    }
  }
}

.Button--outlined {
  @mixin gordita-medium;
  background: none;
  border: 1px solid $otherGrey;
  border-radius: 4px;
  color: $black;

  &:hover {
    background-color: $grey;
    color: $black;
  }
}

.ButtonSet {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-bottom: .25rem;
  }

  label {
    line-height: 1;
    text-align: center;
    padding: 9px 1rem 6px 1rem;
    margin-right: -1px;
    height: 100%;
    border: $border;
    display: flex;
    align-items: center;
    background: white;

    small {
      padding-top: .25rem;
      display: inline-block;
      font-size: 75%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label,
  input.selected + label {
    background: $primary;
    color: white;
  }

  & > div:first-of-type label {
    border-radius: 15px 0 0 15px;
  }

  & > div:last-of-type label {
    border-radius: 0 15px 15px 0;
  }

  input[type=checkbox] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
}

.RadioButtonSet .RadioGroup {
  display: flex;

  label {
    line-height: 1;
    text-align: center;
    padding: 13px 1.5rem 10px 1.5rem;
    margin-right: -1px;
    height: 100%;
    border: $border;
    display: flex;
    align-items: center;
    background: white;
    font-size: 1;

    span {
      marign: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  label:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  input[type=radio] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background: $primary;
    color: white;
  }
}

}