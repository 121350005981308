.admin-layout {

.CalendarSync {

  .Box {
    box-shadow: none;
    border: $border;
  }

  .cal-load-img {
    @mixin below $medium {
      display: none;
    }
  }

  .GoogleSignInButton.Button.SecondaryOutlineButton {
    display: flex;

    span {
      margin-top: 2px;
    }

    img {
    vertical-align: middle;
    margin-right: .75rem;
    margin-top: -7px;
    }
  }

  .RefreshCalList {
    margin-left: 1rem;

    @mixin below {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &-statusContainer {
    max-width: 750px;
  }

  &-status {
    line-height: 1.5;
    flex-direction: row;

    .u-text-light {
      margin-top: 3px;
    }

    &--info {
      align-items: center;
    }

    &--sync {
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
    }

    @mixin below $medium {
      flex-direction: column;
      align-items: center;

      &--sync {
        margin-top: 1rem;
        flex-direction: column-reverse;
        align-items: center;

        .Button {
          margin-bottom: 0.5rem !important;
        }

        .u-text-light {
          font-size: 80%;
          margin-right: 0 !important;
        }
      }
    }
  }

  &-status--active {
    color: #0d7332;
  }

  &-status--inactive {
    color: $admin-red;
  }

  &-status--active .icon,
  &-status--inactive .icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }

  &--statusName {
    padding-top: 3px;
  }

  &-icon {
    height: 30px;
  }

}

}