.admin-layout {

.Pagination {
  a {
    color: black;

    &:hover {
      color: $text-light;
    }
  }
}

}