@import 'shared.postcss';

/* Vendor */
@import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '../../node_modules/react-day-picker/lib/style.css';

/* Variables */
@import 'admin/variables.postcss';

/* Utils */
@import 'admin/mixins.postcss';

/* Base */
@import 'admin/base.postcss';

/* Layouts */
@import 'admin/layouts/account-form.postcss';
@import 'admin/layouts/admin-layout.postcss';
@import 'admin/layouts/book-pod.postcss';
@import 'admin/layouts/calendar-sync.postcss';
@import 'admin/layouts/catering.postcss';
@import 'admin/layouts/error-page.postcss';
@import 'admin/layouts/events.postcss';
@import 'admin/layouts/online-ordering.postcss';
@import 'admin/layouts/vendor.postcss';
@import 'admin/layouts/vendor-truck.postcss';

/* Content */
@import 'admin/content/lists.postcss';
@import 'admin/content/react-draft-wysiwyg.postcss';
@import 'admin/content/typography.postcss';
@import 'admin/content/tables.postcss';

/* Components */
@import 'admin/components/box.postcss';
@import 'admin/components/budget-slider.postcss';
@import 'admin/components/buttons.postcss';
@import 'admin/components/card-field.postcss';
@import 'admin/components/comments.postcss';
@import 'admin/components/dashboard-widget.postcss';
@import 'admin/components/datetime.postcss';
@import 'admin/components/document.postcss';
@import 'admin/components/forms.postcss';
@import 'admin/components/geosuggest.postcss';
@import 'admin/components/menu-item.postcss';
@import 'admin/components/pagination.postcss';
@import 'admin/components/photo-item.postcss';
@import 'admin/components/progress-counter.postcss';
@import 'admin/components/react-day-picker.postcss';
@import 'admin/components/react-select.postcss';
@import 'admin/components/recurring-select.postcss';
@import 'admin/components/switch.postcss';
@import 'admin/components/time-picker.postcss';
@import 'admin/components/truck-document.postcss';
