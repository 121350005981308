.admin-layout {

.AdminLayout {
  @mixin above $medium {
    min-height: 100vh;

    .Container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: top;
    }
  }

  @mixin below $medium {

    h2 {
      margin-left: 40px;
      margin-top: .25rem;
    }

    .VendorHeader-badge {
      margin-left: 40px;
    }

    &--sidebarOpen {
      h2 {
        margin-left: 0;
        margin-top: 0;
      }

      .VendorHeader-badge {
        margin-left: 0;
      }
    }
  }

  .ListPage {

    @mixin below {
      .newItemButton {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
      }

      .Table td {
        display: none;

        &:first-child,
        &:last-child {
          display: table-cell;
        }
      }
    }

    td .PlainLinkButton {
      text-align: left;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }

    &-actionsTooltip {
      padding: .5rem;
      padding-bottom: 0;
      padding-top: .25rem;

      a {
        @mixin gordita-medium;
        display: block;
        color: $text-light;

        & + & {
          margin-top: .5rem;
        }

        &:hover {
          color: black;
        }
      }
    }
  }

  .PodListPage .ListPage {
    @mixin below {
      .Table td:nth-child(3) {
        display: table-cell;
      }
    }
  }

}

.AdminSidebar {
  background: white;
  position: relative;
  z-index: 5;

  .menuToggle {
    cursor: pointer;
    padding: 23px;
    background: none;
    border: none;
    margin-bottom: 64px;

    &:focus {
      outline: none;
    }
  }

  .menuToggle-mobile {
    cursor: pointer;
    padding: 23px;
    background: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;

    &:focus {
      outline: none;
    }
  }

  @mixin above $medium {
    box-shadow: 4px 4px 25px rgba(0, 43, 79, 0.15);
    padding: 0;
    flex: 0 0 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    transition-property: flex;

    &.AdminSidebar--open {
      flex: 0 0 220px;

      .menuTitle {
        opacity: 1;
        display: inline-block;
        max-width: 220px;
        transition-duration: 0.1s;
      }
    }

    .LinkButton {
      margin-left: 1rem;
    }
  }
}

.AdminLayout-content {
  flex: 0 1 100%;
}

.AdminLayout-body {
  padding: 1rem;
  @mixin above $medium {
    flex: 0 1 100%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin-bottom: 2rem;
  }
}

.AdminLayout-footer {
  text-align: center;
  color: $text-light;
  font-size: .75rem;
  padding-top: 3rem;
  margin-top: auto;
  line-height: 1;
}

.AdminLayout-mobileMenu {
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  transition-property: max-height;
  margin-bottom: 0;

  @mixin above $medium {
    display: none;
  }

  li {
    margin: 0;
  }

  .AdminLayout-menuItem {
    width: 100%;
    display: flex;
    align-items: center;

    &--noImg {
      height: 54px;
      padding-left: 1.5rem;
    }
  }

  .menuTitle {
    opacity: 1;
    color: black !important;
    display: inline-block;
    line-height: 1;
    margin-bottom: -5px;
  }

  .menuIcon {
    opacity: 1 !important;
    margin-top: -5px;
    margin-bottom: -5px;

    svg path {
      fill: black;
    }
  }
}

@mixin below $medium {
  .AdminSidebar--open {
    background: white;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.AdminSidebar--open .AdminLayout-mobileMenu {
  max-height: unset;
  margin-bottom: 1rem;
  padding-bottom: .5rem;
  height: 100%;
  overflow: auto;
}

.AdminSidebar--open .menuToggle-mobile {
  position: initial;
  padding-top: 21px;
  padding-bottom: 21px;
}

.AdminLayout-backButton {
  background-color: white;
  border-bottom: none;
  padding: 0 1rem 1rem 1rem;
  margin-bottom: 0 !important;
}

.AdminLayout-menu {
  list-style: none;
  text-align: center;
  display: none;

  li {
    display: inline-block;
    padding: 0;
    margin: 0;
    border-bottom: $border;

    &:first-child {
      border-top: $border;
    }

    .AdminLayout-menuItem {
      display: flex;
      align-items: center;
    }
  }

  .menuTitle {
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform, max-width;
    opacity: 0;
    max-width: 0;
    margin-top: 6px;
    line-height: 1;
  }

  @mixin above $medium {
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    li {
      margin-right: 0;
      display: block;
    }
  }

  hr {
    background: #e5e8ed;
    margin-bottom: 0;
  }
}

li.AccountMenuItem {
  .menuIcon {
    height: 64px;
    padding: 16px;
  }

  .menuIcon-photo {
    border-radius: 50%;
    width: 64px;
  }
}

.AdminLayout-menuItem {
  cursor: pointer;

  .menuTitle {
    @mixin gordita-medium;
    color: $text-light;
  }

  &:hover,
  &.AdminLayout-menuItem--tippyShown {

    .menuIcon {
      opacity: 1;
    }

    .menuTitle {
      color: black;
    }
  }

  .menuIcon {
    display: inline-block;
    opacity: 0.25;
  }

}

.AdminLayout-menuItem--active {
  color: black !important;
  transition: none !important;

  .menuIcon {
    opacity: 1;
  }

  .menuTitle {
    color: black;
  }
}

.AdminLayout-tabs {
  display: flex;
  list-style-type: none;

  li {
    margin: 0;
    padding: .5rem 1rem .25rem;
    background-color: white;
    cursor: pointer;

    &.AdminLayout-tabs--active {
      background-color: $primary;
      color: white;
    }

    &:first-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &:last-child {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}

.AdminWrapper {
  margin: 0 auto;
  padding: 2rem 0;

  h2 {
    margin-left: 0;
  }

  hr {
    margin-bottom: 1.5rem;
    margin-top: 1.25rem;
  }

  @mixin above $small {
    background-color: white;
    border: 1px solid $mediumGrey;
    border-radius: 4px;
    max-width: 960px;
    padding: 3rem;
  }
}

.AccountTooltip-inner {
  padding: .25rem;
  padding-bottom: 0;

  a {
    @mixin gordita-medium;
    display: block;
    padding: .5rem;
    color: black;

    &:hover {
      color: $otherGrey;
    }
  }
}

}