.admin-layout {

.LegalList {
  ol {
    list-style: none;
  }

  & > ol > li {
    margin-top: 1rem;

    ol li {
      margin-top: 0.5rem;
    }
  }

  ol > li:before {
    content: attr(data-seq) ".";
    margin-right: 0.5rem;
  }

  ol ol {
    margin-left: 1rem;

    ol {
      margin-left: 2rem;
    }
  }
}

}