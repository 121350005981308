$border-radius: 4px;

.admin-layout {

.Select-control {
  border-color: $border-color !important;
  height: 32px;
  border-radius: $border-radius;

  &:hover {
    box-shadow: none;
  }
}

.Select-input {
  height: 100%;
}

.Select-placeholder {
  color: $text-light;
}

.Select-value-label,
.Select--single > .Select-control .Select-value,
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: black;
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: $border-color;
  box-shadow: none;
}

.Select-menu-outer {
  border: $border;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.Select-option {
  padding: 5px 10px;
  padding-bottom: 2px;

  &:last-child {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &.is-selected,
  &.is-focused {
    background-color: $primary-bg-dark;
  }
}

.Select.Select--blueArrow {

  &.is-open > .Select-control .Select-arrow {
    top: unset;

    &:before {
      content: "\EA06";
    }
  }

  .Select-arrow-zone {
    padding-top: 2px;
  }

  .Select-arrow {
    border: none !important;
    height: unset;
    width: unset;
    display: block;

    &:before {
      display: block;
      font-family: "icons";
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\EA03";
      color: $primary;
    }
  }
}

.Select.Select--rounded {
  .Select-control {
    border-radius: 24px;
  }
}

.Select--multi .Select-value {
  border: none;
  background-color: $bg;
  border-radius: 24px;
  color: black;
  margin-left: 0.75em;
  margin-top: 8px;

  .Select-value-icon,
  .Select-value-label {
  }

  .Select-value-icon {
    border: none;
    padding-top: .5rem;
    padding-left: .5rem;

    &:hover {
      background: none;
      color: #D0021B;
    }
  }

  .Select-value-label {
    padding-right: 8px;
  }
}

.Select--multi .Select-input {
  margin: 0 !important;
}

.Select.Select--rounded {
  min-width: 150px;

  .Select-control {
    border-radius: 16px;
  }

  &.is-open > .Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .Select-option {
    color: $text-light;
  }

  .Select-placeholder,
  .Select-value-label,
  .Select--single > .Select-control .Select-value,
  .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
  .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: $text-light !important;
  }

  .Select-clear {
    padding-top: 6px;
  }
}

}