.admin-layout {

.DayPicker {
  background: white;
  border: $border;
}

.DayPicker-popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .DayPicker {
    clear: both;
    padding: 0;
    padding-top: 1rem;
    border-radius: $radius;
  }
}

.DayPicker-Day {
  line-height: 1.5;
  padding: 7.5px;
  padding-top: 8.5px;
  padding-bottom: 4.5px;

  &--today {
    color: $primary;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $primary;
}

}