.admin-layout {

.Document {
  background: white;
  border-bottom: 1px solid #ccc;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  max-width: 450px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);

  @mixin above $small {
    font-size: 0.875rem;
    min-width: 300px;
  }

  &--header {
    padding-bottom: .5rem !important;
  }

  &--section {
    padding: 0.75rem 1rem;
  }

  &--section--missing&--admin--true {
    display: none;
  }

  &--header + &--section {
    border-top: 1px solid #eee;
  }

  &--expiration {
    margin-top: 1rem;

    .FormRow {
      padding: 0;
    }
  }

  .Status {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: white;
    border-radius: 3px;
    padding: 3px 8px 0 8px;
    min-width: 75px;

    &--approved {
      background-color: $admin-green;
    }

    &--rejected {
      background-color: $admin-red;
    }

    &--submitted {
      background-color: $grey;
    }

    &--missing, &--expired {
      background-color: #293C82;
    }
  }

  .icon {
    vertical-align: middle;
    font-size: 1rem;
    color: #A9ACB5;
    display: inline-block;
    margin-right: 0.5rem;
  }

}

}