.admin-layout {

.PhotoItems {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -0.75rem;
}

.PhotoItem {
  flex-basis: 33.3333%;
  border-radius: $radius;
  padding: 0.75rem;
  border-radius: 4px;
  height: 16vw;
  max-height: 300px;

  h4 {
    width: 100%;
    max-width: 10rem !important;
  }

  img {
    display: block;
    max-width: 100%;
  }

  &--upload {
    .FileField {
      height: 100%;
    }

    .Button {
      width: 100%;
      height: 100%;
    }
  }

  @mixin below $medium {
    flex-basis: 50%;
    height: 30vw;
  }

  @mixin below {
    flex-basis: 100%;
    height: 60vw;
  }
}

.PhotoItem-image {
  display: inline-block;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;

  &-delete {
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    height: 20px;
    line-height: 1.65;
    position: absolute;
    right: 0.5rem;
    text-align: center;
    top: 0.5rem;
    width: 20px;
  }
}

.PhotoItem-image-delete {
  margin-left: auto;
}

.PhotoItem-name {
  margin-bottom: 0;
}

.PhotoItem-description {
  margin-bottom: 0;
}

.PhotoItem-price {
  margin-bottom: 0;
  font-style: italic;
}

}