.admin-layout {

.ErrorPage {
  position: relative;
  min-height: 400px;
}

.ErrorPage-content {
  text-align: center;
  padding-top: 4rem;
}

}