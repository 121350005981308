.admin-layout {

.Comments {
  padding: 0rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 2;
  height: 100%;
}

.Comments-threadWrapper {
  padding: 1rem;
  overflow-y: auto;
  flex: 1 1 auto;
  z-index: 1;
  position: relative;

  @mixin above $medium {
    flex: 1 1 calc(100vh - 297px);

    &.showChargeWarning {
      flex: 1 1 calc(100vh - 378px);
    }
  }
}

.CommentThread {
  margin-bottom: 1rem;
  padding: 0rem;
}

.CommentRow {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
}

.CommentRow--others {
  justify-content: flex-start;
}

.Comment {
  flex: 1 1 auto;
  background: $primary;
  padding: 1rem;
  padding-bottom: 10px;
  border-radius: 1rem;
  color: white;
  max-width: 300px;

  a {
    color: white;
    text-decoration: underline;
  }

  img {
    border-radius: $radius;
    overflow: hidden;
    max-width: 100%;
    display: block;
    margin-bottom: .25rem;
  }

  @mixin above $medium {
    max-width: 400px;
  }
}

.Comment-bidAmount {
  color: white;
}

.Comment-bidAmount--retracted {
  text-decoration: line-through;
}

.Comment--others {
  background: $mediumGrey;
  color: $black;

  a {
    color: $orange;
    text-decoration: underline;
  }

  .Comment-bidAmount {
    color: $black;
  }
}

.Comment-topMeta {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.Comment-time {
  opacity: 0.65;
}

.CommentForm {
  background: white;
  padding: 0.75rem;
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  z-index: 2;

  .FormLabel, .ErrorExplanation {
    display: none;
  }

  .FormRow {
    padding: 0;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .Button.LinkButton {
    margin: 0;
    height: 100%;

    img {
      max-width: unset;
    }

    &.BidSubmit {
      padding-top: 8.5px;
      padding-bottom: 5.5px;
      padding-right: 5px;
      padding-left: 7px;
    }
  }

  @mixin above $medium {
    position: relative;
  }
}

.CommentForm-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  border: $border;
  border-radius: 24px;
  align-items: flex-start;

  .CommentForm-inner--submitContainer {
    margin-top: 3.5px;
    margin-right: 5px;
  }

  .Loader {
    width: 35px;
    height: 35px;
  }
}

.CommentForm-field {
  flex: 1 1 100%;

  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .TextInput, .TextArea  {
    max-width: 100%;
    border: none;
    background: none;
    font-size: 1rem;
    resize: none;

    &:focus {
      outline: none;
      background: none;
      border: none;
    }
  }

  .TextInput {
    height: 39px;
    min-height: 0;
  }

  .TextArea {
    height: unset;
    min-height: unset;
    max-height: 100px;
  }

  &.CommentForm-amount {
    flex: auto;
    border-right: $border;
    align-self: stretch;
    display: flex;

    input {
      @mixin gordita-medium;
      padding-left: 0;
    }

    .u-strong {
      padding-top: 12px;
      padding-left: .75rem;
    }
  }
}

}