.admin-layout {

.rc-time-picker {
  width: 170px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.rc-time-picker-input {
  width: 100%;
  cursor: text;
  border: 1px solid $mediumGrey;
}

.rc-time-picker-input,
.rc-time-picker-panel-input {
  font-size: 1rem;
  display: inline-block;
  position: relative;
  background: white;
  padding: 0.5em 0.75em 0.25rem;
  line-height: 1;
  border-radius: $radius;
  height: 3rem;
}

.rc-time-picker-panel {
  z-index: 1510;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
  padding-top: 2px;

  * {
    box-sizing: border-box;
  }

  &-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 3px;
    background-clip: padding-box;
    border: 1px solid $mediumGrey;
    line-height: 1.5;
  }
}

.rc-time-picker-panel-input {
    margin: 0;
    width: 100%;
    cursor: auto;
    outline: 0;
    border: none;

    &-wrap {
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #e9e9e9;
    }

    &-invalid {
      border-color: red;
    }
  }

.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;

  &:after {
    content: "x";
    font-size: 12px;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    width: 20px;
    transition: color 0.3s ease;
  }

  &:hover:after {
    color: #666;
  }
}

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative; /* Fix chrome weird render bug */

  &-active {
    overflow-y: auto;
  }

  &:first-child {
    border-left: 0;
    margin-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 144px;
  }

  li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #edfaff;
    }
  }

  li&-option-selected {
    background: #edfaff;
    color: #2db7f5;
  }

  li&-option-disabled {
    color: #bfbfbf;
    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
}

}