.admin-layout {

.Box {
  box-shadow: $box-shadow;

  &.Box--border {
    box-shadow: none;
    border: $border;
  }
}

}